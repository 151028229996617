.bg-moved-down {
  object-position: 0 5px;
}

.bg-pic-profile{
  object-fit: none;
  object-position: -20px 0px;
}

#sidebar-menu>ul>li>a i {
  width: 25px;
  font-size: 1.3rem;
}